








































import { Component, Provide } from 'vue-property-decorator';
import PillComponent from '@/components/pill/PillComponent.vue';
import FeedItemComponent from '@/components/feed/FeedItemComponent.vue';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import WidgetHelper from '@/utils/helpers/widgets/WidgetHelper';
import { Location } from 'vue-router';
import FeedType from '@/utils/enums/feed/FeedType';
import ButtonComponent from '@/components/ButtonComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';

@Component({
  components: {
    FeedItemComponent,
    PillComponent,
    ButtonComponent,
    FontAwesomeComponent,
  },
})
export default class FeedItemWrapperWidget extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  @Provide()
  private feedType = FeedType.GLOBAL;

  private commentId: string | null = null;

  private get singlePostPage(): boolean {
    return this.code === 'FeedPostDetail';
  }

  created(): void {
    if (this.payload
      && 'entityCode' in this.payload
      && this.payload.entityCode) {
      const entityCode = WidgetHelper
        .entityCode(this.payload.entityCode as string, this.$route as unknown as Location);
      this.setDataConfig([{
        fragmentName: 'feedItemWrapperWithReactionsFragment',
        operation: 'feedItemWrapper',
        alias: this.storeName,
        gqlDefinition: buildQueryDefinition({
          filter: {
            type: GqlEntityFilterType.FEED_ITEM_WRAPPER_FILTER,
            value: {
              uid: entityCode,
            },
          },
        }),
      }]);
      if ('commentEntityCode' in this.payload && this.payload.commentEntityCode) {
        const commentIdNotFound = 'notFound';
        const foundCommentId = WidgetHelper.entityCode(
          this.payload.commentEntityCode as string, this.$route as unknown as Location, commentIdNotFound,
        );
        if (foundCommentId !== commentIdNotFound) {
          this.commentId = foundCommentId;
        }
      }
    }
  }

  private onClick(): void {
    this.$router.back();
  }
}
